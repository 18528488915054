body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'sans-serif', Segoe, "Segoe UI", Calibri, 'Noto Sans JP', Arial, 'Roboto', 'Helvetica Neue', sans-serif, Candara, Optima;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff9ff;
    text-align: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: border-box;
    /*font-family: Segoe, "Segoe UI", Calibri, Arial, sans-serif, Candara, Optima;*/
}

a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #fafafa;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}
